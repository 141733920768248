import createReducer from './createReducer';
import { AuditActions } from '../actions';
import { AUDIT } from '../constants/actions';

export const audit = createReducer(
	{},
	{
		[AUDIT.AUDIT_LOG_LIST_RESPONSE](state = {}, action: ReturnType<typeof AuditActions.setAuditLogList>) {
			return { ...state, auditList: action.payload };
		},
	},
);
