import * as React from 'react';
import { Button, CircularProgress, FormControl, Grid, InputLabel, Paper, TextField } from '@material-ui/core';
import { CommonStyles, getModalStyle, getModalClasses, globalColors } from '../../hooks/styles';
import { useMediaQuery } from 'react-responsive';
import CancelIcon from '@material-ui/icons/Cancel';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import RemoveCircleIcon from '@material-ui/icons/RemoveCircle';
import { SelectBox } from '../../components/selectBox';
import { Autocomplete } from '@material-ui/lab';
import { connect } from 'react-redux';
import { OrderActions } from '../../redux/actions';
import { Remove } from '@material-ui/icons';

const initProductOptions = (order: any) => {
  const initProduct = { label: `${order.productName} (${order.productId})`, value: order.productId }
  let options = [initProduct]
  order.relatedProducts && order.relatedProducts.forEach((product: any) => {
    options.push({ label: `${product.productName} (${product.productId})`, value: product.productId })
  })
  return options
}

const getBatchOptions = (order: any) => {
  let options: any[] = [{label: 'Empty', value: 'empty'}]
  let list: any[] = []
  const whPrefrenceMap: any = {
    0: '1st',
    1: '2nd',
    2: '3rd',
  }
  Object.keys(order.warehouseInventory).forEach((warehouse: string, index: number) => {
    options.push({listSubheader: true, label: `${whPrefrenceMap[index]} - ${order.warehouseInventory[warehouse].length > 0 ? order.warehouseInventory[warehouse][0].warehouse : '(none)'}`})
    order.warehouseInventory[warehouse].forEach((batch: any) => {
      const expiresIn = Math.floor((new Date(batch.expirationDate).getTime() - new Date().getTime()) / (1000 * 3600 * 24))
      options.push({
        label: `lot: ${batch.lotNumber} - qty: ${batch.warehouseQty} - exp: ${batch.expirationDate.split('T')[0]} (${expiresIn} days)`,
        value: batch.id
      })
      list.push(batch)
    })
  })
  return {initBatchOptions: options, initBatchList: list}
}

const CreateOrderModal: React.FC<any> = (props: any) => {
	const commonStyles = CommonStyles();
	const modalClasses = getModalClasses();
	const isDesktopOrLaptop = useMediaQuery({
		query: '(min-device-width: 1224px)',
	});
  const [customer, setCustomer] = React.useState<any>(null);
  const [customerProductsLoading, setCustomerProductsLoading] = React.useState<any>(false)
  const [customerProductsError, setCustomerProductsError] = React.useState<string>('')
  const [customerProducts, setCustomerProducts] = React.useState<any>([]);
  const [orderLines, setOrderLines] = React.useState<any[]>([{}])
	const [errors, setErrors] = React.useState<any>({});

	const submit = () => {
    if (validateForm()) {
      return
    }

		let orderPayload = getOrderPayload()
    props.submitOrder(orderPayload);
    props.close(false);
	};

  const validateForm = () => {
    let formErrors: any = {}
    orderLines.forEach((orderLine: any, index: number) => {
      const lineErrors: any = {}
      if (!orderLine.product ) {
        lineErrors['product'] = 'Please select a valid product ID.'
      }
      if (!orderLine.qty || isNaN(orderLine.qty) || orderLine.qty < 1) {
        lineErrors['qty'] = 'Invalid qty.'
      }
      if (showBatchSelectionForLine(orderLine?.product) && (!orderLine.batch)) {
        lineErrors['batch'] = 'Please select a batch.'
      }
      formErrors[index] = lineErrors
    })
    setErrors(formErrors)
    return Object.keys(formErrors).some((index: string) => Object.keys(formErrors[index]).length > 0)
  }

  const getOrderPayload = () => {
    return orderLines.map((orderLine: any) => (
      {
        customerId: customer.customerId,
        cabinetId: orderLine.product.cabinets && orderLine.product.cabinets[0] ? orderLine.product.cabinets[0].cabinetId : '',
        productId: orderLine.product.productId,
        qty: +orderLine.qty,
        inventoryStatus: orderLine.product.inventoryStatus,
        warehouse: orderLine.batch === 'empty' ? '' : orderLine.batch?.warehouse,
        expiration: orderLine.batch === 'empty' ? '' : orderLine.batch?.expirationDate,
        lot: orderLine.batch === 'empty' ? '' : orderLine.batch?.lotNumber,
        poType: ''
      }
    ))
  }

  const showBatchSelectionForLine = (customerProduct: any) => {
    if (!customerProduct?.warehouseInventory) return false
    return Object.values(customerProduct.warehouseInventory).some((whGroup) => Array.isArray(whGroup) && whGroup.length > 0)
  }

  const getBatchOptionsForLine = (customerProduct: any) => {
    return getBatchOptions(customerProduct)
  }

  const handleInputChange = (value: any, field: string, index: number) => {
    let orderLinesCopy = [...orderLines]
    orderLinesCopy[index][field] = value
    setOrderLines(orderLinesCopy)
    let errorsCopy = {...errors}
    if (errorsCopy[index]) {
      delete errorsCopy[index][field]
      setErrors(errorsCopy)
    }
  }

	return (
		<div
			style={{
				...getModalStyle(),
				width: isDesktopOrLaptop ? 'auto' : '90%',
				maxHeight: 600,
				maxWidth: 750,
				overflow: 'scroll',
			}}
			className={modalClasses.paper}
		>
			<div
				style={{ position: 'absolute', top: 5, right: 5, color: 'rgba(0, 0, 0, 0.26)', cursor: 'pointer' }}
				onClick={() => props.close(false)}
			>
				<CancelIcon />
			</div>
			<h2>Create Order</h2>

			<div style={{ margin: '20px 0 0' }}>
        <FormControl variant="outlined" style={{ marginRight: 10 }}>
          <Autocomplete
            id="customer-combo-box"
            options={props.tenants}
            getOptionLabel={(option: any) => '(' + option.customerId + ') ' + option.name}
            style={{ width: 280 }}
            onChange={(e: any, value: any) => {
              setCustomerProducts([])
              setCustomerProductsError('')
              if (!value) return
              setCustomerProductsLoading(true)
              props.getCustomerProducts({
                customerId: value.customerId,
                params: [],
                onSuccess: (result: any) => {
                  setCustomerProducts(result.restockRecommendations)
                  setCustomerProductsLoading(false)
                },
                onError: (error: string) => {
                  setCustomerProductsError(error)
                  setCustomerProductsLoading(false)
                }
              })
              setCustomer(value)
            }}
            renderInput={(params: any) => (
              <TextField
                value={customer}
                {...params}
                label="Search Customers"
                variant="outlined"
                error={errors.customer ? true : false}
                helperText={errors.customer ? 'Please choose a customer.' : ''}
              />
            )}
          />
        </FormControl>
      </div>
      {customerProductsLoading && <CircularProgress size={20} style={{ margin: '20px 0 0 20px' }} />}
      {customerProductsError && <p style={{ color: 'red' }}>{customerProductsError}</p>}
      {customerProducts.length > 0 &&
        <>
          {orderLines.map((orderLine: any, index: number) => (
            <div key={index} style={{ margin: '25px 0 0 0' }}>
              <div style={{ margin: '0 0 8px 0' }}>
                {orderLine.product && <div><b>Outstanding Order Qty:</b> {orderLine.product.outstandingOrders || 0}</div>}
                {orderLine.product && orderLine.product.warnings.length > 0 && <>{orderLine.product.warnings.map((warning: string, warningIndex: number) => (<div key={`warningIndex-${warningIndex}`} style={{color: 'red'}}>{warning}</div>))}</>}
              </div>
              <FormControl variant="outlined" style={{ marginRight: 10 }}>
                <SelectBox
                  inputLabel={'Select Product'}
                  emptyItemLabel={'Choose'}
                  style={{ width: 200 }}
                  selected={orderLine.product || ''}
                  listItems={customerProducts.map((customerProduct: any) => ({label: `${customerProduct.productName} (${customerProduct.productId})`, value: customerProduct}))}
                  error={errors[index]?.product ? true : false}
                  errorText={errors[index]?.product || ''}
                  onChangeItem={(value: any) => {
                    handleInputChange(value, 'product', index)
                  }}
                />
              </FormControl>
              {(showBatchSelectionForLine(orderLine?.product)) && <FormControl variant="outlined" style={{ marginRight: 10 }}>
                <SelectBox
                  inputLabel={'Select Batch'}
                  style={{ width: 200 }}
                  error={errors[index]?.batch ? true : false}
                  errorText={errors[index]?.batch || ''}
                  selected={orderLine[index]?.batch?.id || 'empty'}
                  listItems={getBatchOptionsForLine(orderLine.product).initBatchOptions}
                  onChangeItem={(value: any) => {
                    const batch = value === 'empty' ? 'empty' : getBatchOptionsForLine(orderLine.product).initBatchList.find((batch: any) => batch.id === value)
                    handleInputChange(batch, 'batch', index)
                  }}
                />
              </FormControl>}
              <FormControl variant="outlined" style={{ marginRight: 10 }}>
                <TextField
                  variant="outlined"
                  type="number"
                  label="Qty"
                  style={{ width: 100 }}
                  required
                  InputProps={{ inputProps: { min: 0, step: 1 } }}
                  value={(orderLine.qty && orderLine.qty >= 0) ? orderLine.qty : ''}
                  error={errors[index]?.qty ? true : false}
                  helperText={errors[index]?.qty || ''}
                  onChange={event => {
                    handleInputChange(event.target.value, 'qty', index)
                  }}
                />
              </FormControl>
              <FormControl style={{ verticalAlign: 'middle', height: (index === 0 || index < orderLines.length - 1) ? '0' : 'auto' }}>
                {index === orderLines.length - 1 && <AddCircleIcon
                  style={{ color: globalColors.FFF_BLUE, cursor: 'pointer' }}
                  onClick={() => {
                    setOrderLines([...orderLines, {}])
                  }} />}
                {index !== 0 && <RemoveCircleIcon
                  style={{ color: 'grey', cursor: 'pointer' }}
                  onClick={() => {
                    let orderLinesCopy = [...orderLines]
                    orderLinesCopy.splice(index, 1)
                    setOrderLines(orderLinesCopy)
                  }} />}
              </FormControl>
            </div>
          ))}
          <div>
            <FormControl>
              <Button
                type="button"
                className={commonStyles.searchButton}
                variant="contained"
                color="primary"
                onClick={submit}
              >
                SUBMIT
              </Button>
            </FormControl>
          </div>
        </> 
      }
    </div>
	);
};

const mapStateToProps = (state: any) => ({
	selectedCustomer: state.ui.selectedCustomer,
	tenants: state.tenants?.tenantsList?.result || [],
});

const mapDispatchToProps = (dispatch: any) => ({
  getCustomerProducts: (payload: any) => dispatch(OrderActions.getCustomerProductsForOrder(payload)),
})

export default connect(mapStateToProps, mapDispatchToProps)(CreateOrderModal);
