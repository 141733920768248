import createReducer from './createReducer';
import { CabinetActions } from '../actions';
import { CABINET } from '../constants/actions';
import { EdgeConfigProperty } from '../../pages/device-config-management/edge-config-property.type';

export const cabinet = createReducer(
	{},
	{
		[CABINET.LIST_RESPONSE](state = {}, action: ReturnType<typeof CabinetActions.cabinetsRequestCompletedAction>) {
			return { ...state, cabinetCollection: action.payload }; //"cabinetCollection" refers to list of cabinets w/o properties
		},

		[CABINET.SET_PROPERTY_LIST](state = {}, action: ReturnType<typeof CabinetActions.setPropertyList>) {
			return { ...state, propertyList: action.payload };
		},

		[CABINET.TABLE_LIST_RESPONSE](
			state = {},
			action: ReturnType<typeof CabinetActions.cabinetsTableRequestCompletedAction>,
		) {
			return { ...state, cabinets: action.payload };
		},

		[CABINET.CLEAR_CABINET_LIST](state = {}, action: ReturnType<typeof CabinetActions.clearCabinetList>) {
			return { ...state, cabinetCollection: [], cabinets: [] };
		},

		[CABINET.STATE.LIST_RESPONSE](state = {}, action: ReturnType<typeof CabinetActions.setStateListAction>) {
			return { ...state, stateList: action.payload };
		},

		[CABINET.SET_CABINET](state = {}, action: ReturnType<typeof CabinetActions.setCabinet>) {
			return { ...state, selectedCabinet: action.payload };
		},

		[CABINET.SET_CABINET_DETAILS](state = {}, action: ReturnType<typeof CabinetActions.setCabinetDetails>) {
			return { ...state, cabinetDetails: action.payload };
		},

		[CABINET.SET_DETAILS_ERROR](state = {}, action: ReturnType<typeof CabinetActions.setCabinetDetailsError>) {
			return { ...state, detailsError: action.payload };
		},

		[CABINET.TEMPERATURE.GET_TEMPERATURE_SUCCESS](
			state = {},
			action: ReturnType<typeof CabinetActions.getTemperatureAction>,
		) {
			return { ...state, cabinetTemperature: action.payload };
		},

		[CABINET.SET_PRODUCTS](state = {}, action: ReturnType<typeof CabinetActions.setCabinetProducts>) {
			return { ...state, products: action.payload };
		},

		[CABINET.SET_CABINET_CONNECTIVITY_GRAPH](
			state = {},
			action: ReturnType<typeof CabinetActions.setCabinetConnectivityGraph>,
		) {
			return { ...state, connectivityGraph: action.payload };
		},

		[CABINET.SET_CABINET_LTEWIFI_GRAPH](state = {}, action: ReturnType<typeof CabinetActions.setCabinetLteWifiGraph>) {
			return { ...state, lteWifiGraph: action.payload };
		},

		[CABINET.SET_CONSIGNMENT_ORDERS](state = {}, action: ReturnType<typeof CabinetActions.setCabinetError>) {
			return { ...state, consignmentOrders: action.payload };
		},

		[CABINET.SET_TELEMETRY_METRICS](state = {}, action: ReturnType<typeof CabinetActions.setTelemetryMetrics>) {
			return { ...state, telemetryMetrics: action.payload };
		},

		[CABINET.SET_CABINET_PRODUCT_INVENTORY](
			state = {},
			action: ReturnType<typeof CabinetActions.setCabinetProductInventory>,
		) {
			return { ...state, cabinetProductInventory: action.payload };
		},

		[CABINET.SET_CYCLE_COUNT_INVENTORY](state = {}, action: ReturnType<typeof CabinetActions.setCycleCountInventory>) {
			return { ...state, cycleCountInventory: action.payload };
		},

		[CABINET.SET_CYCLE_COUNT_INVENTORY_ERROR](
			state = {},
			action: ReturnType<typeof CabinetActions.setCycleCountInventoryError>,
		) {
			return { ...state, cycleCountInventoryError: action.payload };
		},

		[CABINET.SET_CABINET_USERS](state = {}, action: ReturnType<typeof CabinetActions.setCabinetUsers>) {
			return { ...state, users: action.payload };
		},

		[CABINET.SET_SINGLE_CABINET_USER](state = {}, action: ReturnType<typeof CabinetActions.setSingleCabinetUser>) {
			return { ...state, singleCabinetUser: action.payload };
		},

		[CABINET.SET_AVAILABLE_USERS](state = {}, action: ReturnType<typeof CabinetActions.setAvailableUsers>) {
			return { ...state, availableUsers: action.payload };
		},

		[CABINET.CABINET_ERROR](state = {}, action: ReturnType<typeof CabinetActions.setCabinetError>) {
			return { ...state, error: action.payload };
		},

		[CABINET.SET_CABINET_LATEST_SCAN](state = {}, action: ReturnType<typeof CabinetActions.setCabinetLatestScan>) {
			return { ...state, cabinetLatestScanData: action.payload };
		},

		[CABINET.SET_FOUND_PROVIDER](state = {}, action: ReturnType<typeof CabinetActions.setFoundProvider>) {
			return { ...state, foundProvider: action.payload };
		},

		[CABINET.SET_PROPERTIES_BY_CABINET_TYPE](
			state = {},
			action: ReturnType<typeof CabinetActions.setPropertiesByCabinetType>,
		) {
			return { ...state, cabinetTypePropertyList: action.payload };
		},

		[CABINET.SET_EDGE_CONFIG_PROPERTIES](
			state = {},
			action: ReturnType<typeof CabinetActions.setEdgeConfigProperties>,
		) {
			// if a property has values for a dropdown as a string, explode them into an array
			for (const techType in action.payload) {
				if (action.payload.hasOwnProperty(techType)) {
					action.payload[techType].forEach((item: EdgeConfigProperty) => {
						if (item.values !== null && typeof item.values === 'string') {
							item.values = item.values.split(',');
						}
					});
				}
			}
			return { ...state, edgeConfigProperties: action.payload };
		},

		[CABINET.SET_BACK_FROM_DETAILS](state = {}, action: ReturnType<typeof CabinetActions.setBackFromDetails>) {
			return { ...state, backFromDetails: action.payload };
		},

		[CABINET.SET_CABINET_CONFIG_ERROR](state = {}, action: ReturnType<typeof CabinetActions.setCabinetConfigError>) {
			return { ...state, configError: action.payload };
		},

		[CABINET.SET_CONTEXT_CABINETS](state = {}, action: ReturnType<typeof CabinetActions.setContextCabinets>) {
			return { ...state, contextCabinets: action.payload };
		},

		[CABINET.SET_FLEX_CONFIG](state = {}, action: ReturnType<typeof CabinetActions.setFlexConfig>) {
			return { ...state, cabinetFlexConfig: action.payload };
		},

		[CABINET.SET_FLEX_PRODUCT_LIST](state = {}, action: ReturnType<typeof CabinetActions.setFlexProductList>) {
			return { ...state, flexCabinetProductList: action.payload };
		},

		[CABINET.STORE_DEVICE_TAGS](state = {}, action: ReturnType<typeof CabinetActions.storeDeviceTags>) {
			return { ...state, deviceTags: action.payload };
		},

		[CABINET.SET_CABINET_RECEIPTS](state = {}, action: ReturnType<typeof CabinetActions.setCabinetReceipts>) {
			return { ...state, cabinetReceipts: action.payload };
		},
	},
);
