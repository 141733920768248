import * as React from 'react';
import Moment from 'moment';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import HighchartsMore from 'highcharts/highcharts-more';
import { extendMoment } from 'moment-range';
import { CabinetStyles } from './cabinet.styles';
import { Button, Checkbox, FormControlLabel } from '@material-ui/core';
import { Alert, AlertTitle } from '@material-ui/lab';
import { Constants } from '../../constants/Constants';
HighchartsMore(Highcharts);
window.moment = Moment;
const moment = extendMoment(Moment as any);

const CabinetsChartComponent: React.FC<any> = ({ data, dateFormat, timezone, showExport, exportTemperatureCsv }) => {
	const classes = CabinetStyles();
	const [averages, setAverages] = React.useState<any>([]);
	const [alerts, setAlerts] = React.useState<any>([]);
	const [alertMessage, setAlertMessage] = React.useState(null as any);
	const [alertDate, setAlertDate] = React.useState(null as any);
	const [alertTooltipLeft, setAlertTooltipLeft] = React.useState(0);
	const [showAlertTooltip, setShowAlertTooltip] = React.useState(false);
	const [maxTmp, setMaxTmp] = React.useState<any>([]);
	const [minTmp, setMinTmp] = React.useState<any>([]);
	const [showMinMax, setShowMinMax] = React.useState(false);
	const [showMinMaxLegend, setShowMinMaxLegend] = React.useState(false);
	const [showMinMaxText, setShowMinMaxText] = React.useState('Show Min / Max Temp');
	React.useEffect(() => {
		const maxTempData: any[] = [];
		const averageData: any[] = [];
		const minTempData: any[] = [];
		data.temp?.forEach((element: any) => {
			let dateValue: any = moment(element.tempTimestamp).valueOf();
			averageData.push([dateValue, parseFloat(element.avgTemp)]);
			element.maxTemp && maxTempData.push([dateValue, parseFloat(element.maxTemp)]);
			element.minTemp && minTempData.push([dateValue, parseFloat(element.minTemp)]);
		});
		const alertData: any[] = [];
		data.alerts.forEach((element: any) => {
			let dateValue: any = moment(element.alertTimestamp).valueOf();
			alertData.push({
				color: 'red',
				value: dateValue,
				width: 4,
				events: {
					mouseover: (e: any) => {
						setAlertDate(moment(dateValue).format('dddd, MMM Do HH:mm:ss'));
						setAlertMessage(element.alertType.alertType);
						setAlertTooltipLeft(e.x - 390);
						setShowAlertTooltip(true);
					},
					mouseout: () => {
						setShowAlertTooltip(false);
						setAlertMessage(null);
						setAlertDate(null);
					},
				},
			});
		});
		setMaxTmp(maxTempData);
		setMinTmp(minTempData);
		setAverages(averageData);
		setAlerts(alertData);
	}, [data]);

	const options = {
		backgroundColor: 'red',
		time: {
			timezone: timezone,
		},
		title: false,
		xAxis: {
			type: 'datetime',
			labels: {
				format: dateFormat,
			},
			plotLines: alerts,
		},
		yAxis: [
			{
				title: {
					text: 'Temperature °C',
				},
			},
		],
		tooltip: {
			crosshairs: true,
			shared: true,
			valueSuffix: '°C',
		},
		series: [
			{
				name: 'Average',
				data: averages,
				color: 'green',
				zIndex: 1,
				marker: {
					fillColor: 'green',
					lineWidth: 2,
				},
			},
			{
				name: 'Max Tmp',
				data: maxTmp,
				showInLegend: showMinMaxLegend,
				color: 'red',
				zIndex: 1,
				visible: showMinMax,
				marker: {
					fillColor: 'red',
					lineWidth: 2,
				},
			},
			{
				name: 'Min Tmp',
				showInLegend: showMinMaxLegend,
				data: minTmp,
				color: '#7CB5EC',
				zIndex: 1,
				visible: showMinMax,
				marker: {
					fillColor: '#7CB5EC',
					lineWidth: 2,
				},
			},
			{
				color: 'red',
				name: 'Alerts',
				showInLegend: !showMinMaxLegend,
				dashStyle: 'solid',
				marker: {
					enabled: false,
					fillColor: 'red',
				},
			},
		],
		responsive: {
			rules: [
				{
					condition: {
						maxWidth: 500,
					},
					chartOptions: {
						legend: {
							layout: 'horizontal',
							align: 'center',
							verticalAlign: 'bottom',
						},
					},
				},
			],
		},
	};

	const toggleSeries = () => {
		setShowMinMax(!showMinMax);
	};
	React.useEffect(() => {
		if (showMinMax) {
			setShowMinMaxText('Hide Min / Max Temp');
			setShowMinMaxLegend(true);
		} else {
			setShowMinMaxText('Show Min / Max Temp');
			setShowMinMaxLegend(false);
		}
		Highcharts.charts.forEach((chart: any) => {
			if (chart) {
				chart.reflow();
				chart.redraw();
			}
		});
	}, [showMinMax]);
	return (
		<div>
				<div>
					{showExport && <div className={classes.graphExportButton}>
						<Button
							id="export_button"
							className={classes.searchButton}
							style={{ width: 100 }}
							type="button"
							variant="contained"
							color="primary"
							onClick={() => exportTemperatureCsv()}
						>
							Export
						</Button>
					</div>}
					<div className={classes.tmpGraphTitleDiv}>
						{maxTmp.length > 0 && (
							<FormControlLabel
								className={classes.tmpGraphActionButton}
								control={<Checkbox checked={showMinMax} onChange={toggleSeries} name="toggle-min-max" />}
								label={showMinMaxText}
							/>
						)}
						<h4 className={classes.tmpGraphTitle}>Device Temperature</h4>
					</div>
					{data.temp?.length === 0 ? (
						<div>
							<Alert severity="info">
								<AlertTitle>Info</AlertTitle>
								{Constants.ALERT.NO_RECORD_FOUND}
							</Alert>
						</div>
					) :
						<HighchartsReact highcharts={Highcharts} options={options} />
					}
					{showAlertTooltip && (
						<div style={{ position: 'relative' }}>
							<div className={classes.alertTooltip} style={{ left: `${alertTooltipLeft}px` }}>
								<b>Alert</b>
								<br />
								{alertDate}
								<br />
								{alertMessage}
							</div>
						</div>
					)}
				</div>
				<div></div>
		</div>
	);
};

export default CabinetsChartComponent;
