import React from 'react';
import { connect } from 'react-redux';
import { CabinetActions, UIAction } from '../../redux/actions';
import MUIDataTable from 'mui-datatables';
import { startCase } from 'lodash';
import dataList from '../../constants/dataList';
import { Button, FormControl, Grid, IconButton, Modal } from '@material-ui/core';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import CabinetPropertyMetaModal from './cabinet-property-meta.modal';
import { CommonStyles } from '../../hooks/styles';
import { useMediaQuery } from 'react-responsive';
import { valueMapper } from '../../hooks/functions';
import { snakeCase } from 'snake-case';

const CabinetPropertySettingsComponent: React.FC<any> = (props: any) => {
	const isDesktopOrLaptop = useMediaQuery({
		query: '(min-device-width: 1224px)',
	});
	const searchStyles = CommonStyles();
	const [propertySettings, setPropertySettings] = React.useState<any>(null);
	const [propertyMetaToEdit, setPropertyMetaToEdit] = React.useState<any>({});
	const [metaModalOpen, setMetaModalOpen] = React.useState<boolean>(false);

	const fetchCabinetTypeProperties = () => {
		props.showLoader(true);
		props.getCabinetPropertySettings({
			cabinetType: 'all',
			success: () => {
				props.showLoader(false);
			},
			error: () => {
				props.showLoader(false);
			},
		});
	};

	React.useEffect(() => {
		fetchCabinetTypeProperties();
	}, []);

	React.useEffect(() => {
		console.log('SETTINGS', props.cabinetPropertySettings);
		setPropertySettings(props.cabinetPropertySettings);
	}, [props.cabinetPropertySettings]);

	const handleCloseModal = (refresh: boolean = false) => {
		if (refresh) {
			fetchCabinetTypeProperties();
		}
		setMetaModalOpen(false);
	};

	const deleteProperty = (cabinetType: string, propertyKey: string) => {
		props.deleteCabinetProperty({
			cabinetType: cabinetType,
			propertyKey: snakeCase(propertyKey), //db param is snake case, but converted to camel at some point. need to convert back for delete to work
		});
	};

	const [tableHeight, setTableHeight] = React.useState(0);

	// Calculate table body height as a percentage of the viewport height
	React.useEffect(() => {
		const viewportHeight = window.innerHeight;
		const percentageHeight = 70; // Adjust this value as needed
		const calculatedHeight = (percentageHeight / 100) * viewportHeight;
		setTableHeight(calculatedHeight);
	}, []);

	return (
		<div>
			{propertySettings && (
				<>
					<div style={{ margin: '25px 0 10px 0' }} className={searchStyles.searchWrap}>
						<Grid container>
							<Grid item xs={12}>
								{props.authUser?.authorizationCheck({ action: 'create', resource: 'cabinets', tenantId: 'ALL' }) && (
									<FormControl className={searchStyles.formControl} style={{ float: 'right' }}>
										<Button
											style={{ marginTop: 10, fontSize: '10px' }}
											className={searchStyles.viewAllBtn}
											variant="contained"
											color="secondary"
											onClick={() => {
												setMetaModalOpen(true);
												setPropertyMetaToEdit({});
											}}
										>
											Add New Property
										</Button>
									</FormControl>
								)}
							</Grid>
						</Grid>
					</div>
					<MUIDataTable
						data={propertySettings}
						columns={[
							{
								label: 'Device Type',
								name: 'cabinet_type',
								options: {
									customBodyRender: (value: any, tableMeta: any) => {
										return valueMapper(value, 'cabinetTypes');
									},
								},
							},
							{
								label: 'Property Name',
								name: 'property_key',
								options: {
									customBodyRender: (value: any, tableMeta: any) => {
										return startCase(value);
									},
								},
							},
							{
								label: 'Value Type',
								name: 'value_type',
								options: {
									customBodyRender: (value: any, tableMeta: any) => {
										const thisPropertyMeta = propertySettings[tableMeta.rowIndex];
										if (value === 'string' && thisPropertyMeta.values) {
											return `Dropdown Options: ${thisPropertyMeta.values}`;
										}
										if (value === 'string') {
											return 'Text';
										}
										if (value === 'boolean') {
											return 'Switch';
										}
										return startCase(value);
									},
								},
							},
							{
								label: 'Editable',
								name: 'editable',
								options: {
									customBodyRender: (value: any, tableMeta: any) => {
										return value ? 'Yes' : 'No';
									},
								},
							},
							{
								label: 'Searchable',
								name: 'searchable',
								options: {
									customBodyRender: (value: any, tableMeta: any) => {
										return value ? 'Yes' : 'No';
									},
								},
							},
							{
							
								label: 'Is Part Of Edge Config',
								name: 'is_part_of_edge_config',
								options: {
									customBodyRender: (value: any, tableMeta: any) => {
										return value ? 'Yes' : 'No';
									},
								},
							},
							{
								label: 'Editable By',
								name: 'editable_by',
								options: {
									customBodyRender: (value: any, tableMeta: any) => {
										return value ? `${startCase(value)}s` : 'Not Editable';
									},
								},
							},
							{
								label: 'Visible To',
								name: 'visible_to',
								options: {
									customBodyRender: (value: any, tableMeta: any) => {
										return `${startCase(value)}s`;
									},
								},
							},
							{
								label: '',
								name: '',
								options: {
									change: false,
									filter: false,
									viewColumns: false,
									customBodyRenderLite: (dataIndex: number) => (
										<>
											<IconButton
												onClick={() => {
													setMetaModalOpen(true);
													setPropertyMetaToEdit(propertySettings[dataIndex]);
												}}
											>
												<EditIcon fontSize="small" />
											</IconButton>
											<IconButton
												onClick={() => {
													deleteProperty(
														propertySettings[dataIndex].cabinet_type,
														propertySettings[dataIndex].property_key,
													);

													const psCopy = [...propertySettings];
													psCopy.splice(dataIndex, 1);
													setPropertySettings(psCopy);
												}}
											>
												<DeleteIcon fontSize="small" />
											</IconButton>
										</>
									),
								},
							},
						]}
						options={{
							rowsPerPageOptions: dataList.PageLimitOptions,
							filterType: 'dropdown',
							responsive: 'simple',
							filter: false,
							search: true,
							download: true,
							print: true,
							selectableRows: 'none',
							fixedHeader: true, // This locks the table headers at the top
							tableBodyHeight: tableHeight + 'px', // Set the height for the table body
						}}
					/>
				</>
			)}
			<Modal
				disableBackdropClick={true}
				open={metaModalOpen}
				aria-labelledby="simple-modal-title"
				aria-describedby="simple-modal-description"
			>
				<CabinetPropertyMetaModal cabinetPropertyMeta={propertyMetaToEdit} closeModal={handleCloseModal} />
			</Modal>
		</div>
	);
};

const mapStateToProps = (state: any) => ({
	cabinetPropertySettings: state.cabinet.cabinetTypePropertyList,
	authUser: state.user.authUser,
});

const mapDispatchToProps = (dispatch: any) => ({
	showLoader: (payload: any) => dispatch(UIAction.showLoader(payload)),
	getCabinetPropertySettings: (payload: any) => dispatch(CabinetActions.getPropertiesByCabinetType(payload)),
	saveCabinetProperties: (payload: any) => dispatch(CabinetActions.saveCabinetProperties(payload)),
	deleteCabinetProperty: (payload: any) => dispatch(CabinetActions.deleteCabinetProperty(payload)),
});

export default connect(mapStateToProps, mapDispatchToProps)(CabinetPropertySettingsComponent);
