import { call, put, select, delay } from 'redux-saga/effects';
import { AlertActions, CabinetActions, UIAction, UserActions } from '../actions/index';
import { AlertsService, CabinetService, InventoryManagementService, OrdersService, UserService } from '../services';
import { Constants } from '../../constants/Constants';
import { formatCabinetPropertyKey, formatCabinetPropertyValue } from '../../hooks/functions/CabinetPropertyUtils';
import { Cabinet } from '../../components/autoComplete';
import { userResourceCheck } from '../../pages/users/users.helper';

export function* getCabinets(action: ReturnType<typeof CabinetActions.cabinetRequestStartAction>) {
	try {
		const { data } = yield call(CabinetService.getInstance().getCabinets, action.payload);
		yield put(CabinetActions.cabinetsRequestCompletedAction(data));
	} catch (err) {
		console.log('saga error' + err);
	}
}

export function* getContextCabinets(action: ReturnType<typeof CabinetActions.getContextCabinets>) {
	try {
		const { data } = yield call(CabinetService.getInstance().getContextCabinets, action.payload);
		yield put(CabinetActions.setContextCabinets(data));
	} catch (err) {
		console.log('saga error' + err);
	}
}

export function* getCabinetsTable(action: ReturnType<typeof CabinetActions.cabinetsTableRequestStartAction>): any {
	try {
		yield put(UIAction.setApiError(null));
		yield put(UIAction.showLoader(true));
		const authUser = yield select(state => state.user.authUser);
		const userHasCabinetAccess = userResourceCheck('cabinets', authUser.record.claims);
		if (userHasCabinetAccess) {
			const { data } = yield call(CabinetService.getInstance().getCabinetsWithProperties, action.payload);
			yield put(CabinetActions.cabinetsTableRequestCompletedAction(data));
			if (!data || !data.result || data.result.length === 0) {
				yield put(
					UserActions.setSnackbarMessage({
						message: Constants.ALERT.NO_RECORD_FOUND,
						type: 'info',
					}),
				);
			}
		} else {
			yield put(CabinetActions.cabinetsTableRequestCompletedAction([]));
		}
		yield put(UIAction.showLoader(false));
	} catch (err: any) {
		yield put(UIAction.showLoader(false));
		yield put(CabinetActions.cabinetsTableRequestCompletedAction([]));
		const errorMessage = err.message.includes('403') ? Constants.ALERT.NOT_AUTHORIZED : Constants.ALERT.SERVER_ERROR;

		yield put(
			UserActions.setSnackbarMessage({
				message: `An error occurred: ${errorMessage} in getCabinetsTable`,
				type: 'error',
			}),
		);
	}
}

export function* getCabinetsStateList(action: ReturnType<typeof CabinetActions.getStateListAction>) {
	try {
		const { data } = yield call(CabinetService.getInstance().fetchCabinetStates, action.payload);
		yield put(CabinetActions.setStateListAction(data));
	} catch (err) {
		yield put(
			UserActions.setSnackbarMessage({
				message: `An error occurred: ${err}`,
				type: 'error',
			}),
		);
	}
}

export function* getCabinetDetails(action: ReturnType<typeof CabinetActions.getCabinetDetailsRequest>): any {
	try {
		yield put(UIAction.showLoader(true));
		yield put(CabinetActions.setCabinetDetailsError(false));
		window.history.pushState({}, '', `/cabinet-details/${action.payload.customerId}/${action.payload.cabinetId}`);
		const selectedCabinetResult = yield call(CabinetService.getInstance().getCabinetsWithProperties, {
			customerId: action.payload.customerId,
			cabinetId: action.payload.cabinetId,
		});
		//     where: { customerId: action.payload.customerId, cabinetId: action.payload.cabinetId },
		//     fields: { "customerId": true, "cabinetId": true, "cabinetName": true, "type": true, "state": true, "consignment": true, "mode": true }
		// });
		const selectedCabinet = selectedCabinetResult.data.result[0];
		yield put(CabinetActions.setCabinet(selectedCabinet));
		if (selectedCabinet.cabinetType === 'EM') {
			try {
				yield put(CabinetActions.setCabinetConfigError(null));
				yield put(CabinetActions.setCabinetDetails(null));
				const cabinetDetailsResult = yield call(
					CabinetService.getInstance().getCabinetDetails,
					action.payload.cabinetId,
					action.payload.customerId,
				);
				yield put(CabinetActions.setCabinetDetails(cabinetDetailsResult.data));
			} catch (err) {
				yield put(CabinetActions.setCabinetConfigError('Failed to get mini bar cabinet configuration'));
			}
		}
		yield put(UIAction.showLoader(false));
	} catch (err) {
		console.log('Cabinet Details Saga Error', err);
		yield put(UIAction.showLoader(false));

		yield put(
			UserActions.setSnackbarMessage({
				message: `An error occurred: ${err}`,
				type: 'error',
			}),
		);
		//yield put(CabinetActions.setCabinetDetailsError(true));
	}
}

export function* getPropertyList(action: ReturnType<typeof CabinetActions.getPropertyList>) {
	try {
		const { data } = yield call(CabinetService.getInstance().getPropertyList);
		const withFormat = data.map((property: any) => {
			return Object.assign(property, {
				formattedPropertyKey: formatCabinetPropertyKey(property.propertyKey),
				formattedPropertyValue: formatCabinetPropertyValue(property.propertyKey, property.propertyValue),
			});
		});
		yield put(CabinetActions.setPropertyList(withFormat));
	} catch (e) {
		console.log('Cabinet Property List Saga Error', e);

		yield put(
			UserActions.setSnackbarMessage({
				message: `An error occurred: ${e}`,
				type: 'error',
			}),
		);
	}
}

export function* getTemperature(action: ReturnType<typeof CabinetActions.getTemperatureAction>): any {
	try {
		yield put(UIAction.showLoader(true));
		yield put(UIAction.setApiError(null));
		const data = yield call(CabinetService.getInstance().getCabinetTemperature, action.payload.tempFilter);
		yield put(CabinetActions.getTemperatureSuccessAction(data));
		if (!data || data.length === 0) {
			yield put(
				UserActions.setSnackbarMessage({
					message: Constants.ALERT.NO_RECORD_FOUND,
					type: 'info',
				}),
			);
		}
		const alertsList = yield call(AlertsService.getInstance().getAlerts, action.payload.alertFilter);
		yield put(AlertActions.alertsRequestCompletedAction(alertsList));
		yield put(UIAction.showLoader(false));
		if (!alertsList || !alertsList.data.result || alertsList.data.result.length === 0) {
			// yield put(
			// 	UserActions.setSnackbarMessage({
			// 		message: Constants.ALERT.NO_RECORD_FOUND,
			// 		type: 'info',
			// 	}),
			// );
		}
	} catch (err: any) {
		yield put(UIAction.showLoader(false));
		console.log('saga error', err);
		const errorMessage = err.message.includes('403') ? Constants.ALERT.NOT_AUTHORIZED : Constants.ALERT.SERVER_ERROR;

		yield put(
			UserActions.setSnackbarMessage({
				message: `An error occurred: ${errorMessage} in getTemperature`,
				type: 'error',
			}),
		);
	}
}

export function* getTelemetryMetrics(action: ReturnType<typeof CabinetActions.getTelemetryMetrics>): any {
	try {
		yield put(UIAction.showLoader(true));
		yield put(UIAction.setApiError(null));
		const { data } = yield call(CabinetService.getInstance().getTelemetryMetrics, action.payload.metricsFilter);
		yield put(CabinetActions.setTelemetryMetrics(data));
		if (!data || Object.keys(data).length === 0) {
			yield put(
				UserActions.setSnackbarMessage({
					message: 'No temperature records found.',
					type: 'info',
				}),
			);
		}
		const alertsList = yield call(AlertsService.getInstance().getAlerts, action.payload.alertFilter);
		yield put(AlertActions.alertsRequestCompletedAction(alertsList));
		yield put(UIAction.showLoader(false));
		if (!alertsList || !alertsList.data.result || alertsList.data.result.length === 0) {
			yield put(
				UserActions.setSnackbarMessage({
					message: 'No alert records found.',
					type: 'info',
				}),
			);
		}
	} catch (err: any) {
		yield put(UIAction.showLoader(false));
		console.log('saga error', err);
		const errorMessage = err.message.includes('403') ? Constants.ALERT.NOT_AUTHORIZED : Constants.ALERT.SERVER_ERROR;

		yield put(
			UserActions.setSnackbarMessage({
				message: `An error occurred: ${errorMessage} in getTelemetryMetrics`,
				type: 'error',
			}),
		);
	}
}

export function* getTemperatureCsv(action: ReturnType<typeof CabinetActions.getTemperatureCsvAction>) {
	try {
		const { data } = yield call(
			CabinetService.getInstance().getCabinetTemperatureCsv,
			action.payload.customerId,
			action.payload.deviceId,
			action.payload.timezone,
			action.payload.startTime,
			action.payload.endTime,
		);
		var blob = new Blob([data]);
		var link = document.createElement('a');
		link.href = window.URL.createObjectURL(blob);
		link.download = `${action.payload.deviceId}_temperature_report_${action.payload.endTime}.csv`;
		link.click();
	} catch (err: any) {
		yield put(UIAction.showLoader(false));
		const errorMessage = err.message.includes('403') ? Constants.ALERT.NOT_AUTHORIZED : Constants.ALERT.SERVER_ERROR;

		yield put(
			UserActions.setSnackbarMessage({
				message: `An error occurred: ${errorMessage} in getTemperatureCsv`,
				type: 'error',
			}),
		);
		console.log('Export Temperature CSV Saga Error', err);
		return;
	}
}

export function* getCabinetProducts(action: ReturnType<typeof CabinetActions.getCabinetProducts>) {
	try {
		yield put(UIAction.showLoader(true));
		yield put(CabinetActions.setCabinetDetailsError(false));
		const { data } = yield call(CabinetService.getInstance().getCabinetProducts, action.payload);
		yield put(CabinetActions.setCabinetProducts(data));
		yield put(UIAction.showLoader(false));
	} catch (err) {
		console.log('Cabinet Products Saga Error', err);
		yield put(UIAction.showLoader(false));

		yield put(
			UserActions.setSnackbarMessage({
				message: `An error occurred: ${err}`,
				type: 'error',
			}),
		);
	}
}

export function* getCycleCountInventory(action: ReturnType<typeof CabinetActions.getCycleCountInventory>) {
	try {
		yield put(CabinetActions.setCycleCountInventoryError(false));
		yield put(CabinetActions.setCycleCountInventory(null));
		const { data } = yield call(InventoryManagementService.getInstance().getCustomerCabinetCycleCounts, [
			{ customerId: action.payload.customerId, cabinetId: action.payload.cabinetId },
		]);
		yield put(CabinetActions.setCycleCountInventory({ result: data }));
	} catch (err) {
		console.log('Cycle Count Inventory Saga Error', err);

		yield put(
			UserActions.setSnackbarMessage({
				message: `An error occurred: ${err}`,
				type: 'error',
			}),
		);
	}
}

export function* updatePoType(action: ReturnType<typeof CabinetActions.updatePoType>) {
	try {
		yield put(UIAction.showLoader(true));
		yield put(UIAction.setApiError(null));
		yield call(CabinetService.getInstance().updatePoType, action.payload);
		yield put(UIAction.showLoader(false));
	} catch (err: any) {
		yield put(UIAction.showLoader(false));
		const errorMessage = err.message.includes('403') ? Constants.ALERT.NOT_AUTHORIZED : Constants.ALERT.SERVER_ERROR;

		yield put(
			UserActions.setSnackbarMessage({
				message: `An error occurred: ${errorMessage} in updatePoType`,
				type: 'error',
			}),
		);
		console.log('Cabinet Product PO Type Error', err);
		return;
	}
}

export function* setParLevels(action: ReturnType<typeof CabinetActions.setCabinetProductPar>) {
	try {
		yield put(UIAction.showLoader(true));
		yield put(UIAction.setApiError(null));
		yield call(CabinetService.getInstance().updateParLevels, action.payload);
		yield put(UIAction.showLoader(false));
	} catch (err: any) {
		yield put(UIAction.showLoader(false));
		const errorMessage = err.message.includes('403') ? Constants.ALERT.NOT_AUTHORIZED : Constants.ALERT.SERVER_ERROR;

		yield put(
			UserActions.setSnackbarMessage({
				message: `An error occurred: ${errorMessage} in setParLevels`,
				type: 'error',
			}),
		);
		console.log('Set Cabinet Product Par Levels Error', err);
		yield put(UIAction.showLoader(false));
		return;
	}
}

export function* updateCabinet(action: ReturnType<typeof CabinetActions.updateCabinet>) {
	try {
		yield put(UIAction.showLoader(true));
		yield put(UIAction.setApiError(null));
		yield call(
			CabinetService.getInstance().updateCabinet,
			action.payload.cabinetId,
			action.payload.customerId,
			action.payload.data,
		);
		yield put(UIAction.showLoader(false));
	} catch (err: any) {
		yield put(UIAction.showLoader(false));
		const errorMessage = err.message.includes('403') ? Constants.ALERT.NOT_AUTHORIZED : 'Failed to update cabinet info';

		yield put(
			UserActions.setSnackbarMessage({
				message: `An error occurred: ${errorMessage}`,
				type: 'error',
			}),
		);
		console.log('Update cabinet error', err);
	}
}

export function* getAvailableUsers(action: ReturnType<typeof CabinetActions.getAvailableUsers>): any {
	try {
		yield put(UIAction.setApiError(null));
		yield put(CabinetActions.setAvailableUsers(null));
		const usersList = yield call(UserService.getInstance().getUserList, [
			{
				key: 'tenantId',
				value: action.payload.customerId,
			},
			{
				key: 'limit',
				value: 999,
			},
		]);
		yield put(CabinetActions.setAvailableUsers(usersList));
	} catch (err: any) {
		console.log('Available Users saga error:', err);

		yield put(
			UserActions.setSnackbarMessage({
				message: `An error occurred: ${err}`,
				type: 'error',
			}),
		);
		//yield delay(Constants.ALERT.DELAY);
		//yield put(UIAction.setApiError(null));
	}
}

export function* getUsers(action: ReturnType<typeof CabinetActions.getCabinetUsers>) {
	try {
		yield put(UIAction.showLoader(true));
		yield put(CabinetActions.setCabinetUsers(null));
		const { data } = yield call(CabinetService.getInstance().getCabinetUsers, action.payload);
		yield put(CabinetActions.setCabinetUsers(data));
		yield put(UIAction.showLoader(false));
	} catch (err: any) {
		console.log('Cabinet Users Saga Error', err);
		yield put(UIAction.showLoader(false));
		const errorMessage = err.message.includes('403') ? Constants.ALERT.NOT_AUTHORIZED : Constants.ALERT.SERVER_ERROR;

		yield put(
			UserActions.setSnackbarMessage({
				message: `An error occurred: ${errorMessage} in getUsers`,
				type: 'error',
			}),
		);
	}
}

export function* getSingleCabinetUser(action: ReturnType<typeof CabinetActions.getSingleCabinetUser>) {
	try {
		yield put(UIAction.showLoader(true));
		yield put(CabinetActions.setSingleCabinetUser(null));
		const { data } = yield call(
			CabinetService.getInstance().getCabinetUsers,
			action.payload.cabinetId,
			action.payload.cabinetUserId,
		);
		yield put(CabinetActions.setSingleCabinetUser(data[0]));
		if (action.payload.callback) {
			action.payload.callback();
		}
		yield put(UIAction.showLoader(false));
	} catch (err: any) {
		const errorMessage = err.message.includes('403') ? Constants.ALERT.NOT_AUTHORIZED : Constants.ALERT.SERVER_ERROR;

		yield put(
			UserActions.setSnackbarMessage({
				message: `An error occurred: ${errorMessage} in getSingleCabinetUser`,
				type: 'error',
			}),
		);
		console.log('Single Cabinet User Saga Error', err);
		yield put(UIAction.showLoader(false));
	}
}

export function* addCabinetUser(action: ReturnType<typeof CabinetActions.addCabinetUser>) {
	try {
		yield put(UIAction.showLoader(true));
		yield call(CabinetService.getInstance().addCabinetUser, action.payload.cabinetId, {
			email: action.payload.email,
			role: action.payload.role,
			firstName: action.payload.firstName,
			lastName: action.payload.lastName,
		});
		yield put(CabinetActions.setCabinetUsers(null));
		const { data } = yield call(CabinetService.getInstance().getCabinetUsers, action.payload.cabinetId);
		yield put(CabinetActions.setCabinetUsers(data));
		yield put(UIAction.showLoader(false));
		yield call(action.payload.close);
	} catch (err: any) {
		console.log('Add Cabinet Users Saga Error', err);
		const cabinetUserError = err.message.includes('403')
			? Constants.ALERT.NOT_AUTHORIZED
			: Constants.ALERT.SERVER_ERROR;

		yield put(
			UserActions.setSnackbarMessage({
				message: `An error occurred: ${cabinetUserError} in addCabinetUser`,
				type: 'error',
			}),
		);
		yield put(UIAction.showLoader(false));
	}
}

export function* updateCabinetUser(action: ReturnType<typeof CabinetActions.updateCabinetUser>) {
	try {
		yield put(UIAction.showLoader(true));
		const close = action.payload.close;
		const cabinetId = action.payload.cabinetId;
		delete action.payload.close;
		delete action.payload.cabinetId;
		yield call(CabinetService.getInstance().updateCabinetUser, action.payload);
		const { data } = yield call(CabinetService.getInstance().getCabinetUsers, cabinetId);
		yield put(CabinetActions.setCabinetUsers(data));
		yield call(close, false);
		yield put(UIAction.showLoader(false));
	} catch (err: any) {
		console.log('Update Cabinet Users Saga Error', err);
		yield put(UIAction.showLoader(false));
		const updateUserError = err.message.includes('403') ? Constants.ALERT.NOT_AUTHORIZED : Constants.ALERT.SERVER_ERROR;

		yield put(
			UserActions.setSnackbarMessage({
				message: `An error occurred: ${updateUserError} in updateCabinetUser`,
				type: 'error',
			}),
		);
	}
}

export function* createCabinet(action: ReturnType<typeof CabinetActions.createCabinet>) {
	try {
		yield put(UIAction.showLoader(true));
		const close = action.payload.close || null;
		delete action.payload.close;
		yield call(CabinetService.getInstance().createCabinet, action.payload.cabinet);
		if (close) {
			yield call(close, true);
			return;
		}
		yield put(UIAction.showLoader(false));
	} catch (err: any) {
		const createError = err.message.includes('403') ? Constants.ALERT.NOT_AUTHORIZED : Constants.ALERT.SERVER_ERROR;

		yield put(
			UserActions.setSnackbarMessage({
				message: `An error occurred: ${createError} in createCabinet`,
				type: 'error',
			}),
		);
		yield put(UIAction.showLoader(false));

		console.log('Create Cabinet Saga Error', err);
	}
}

export function* addCabinetProduct(action: ReturnType<typeof CabinetActions.addCabinetProduct>) {
	try {
		yield put(UIAction.showLoader(true));
		const callback = action.payload.callback || null;
		delete action.payload.callback;
		yield call(CabinetService.getInstance().addProduct, action.payload);
		if (callback) {
			yield call(callback, true);
			return;
		}
		yield put(UIAction.showLoader(false));
	} catch (err: any) {
		const createError = err.message.includes('403') ? Constants.ALERT.NOT_AUTHORIZED : Constants.ALERT.SERVER_ERROR;
		yield put(UIAction.showLoader(false));

		yield put(
			UserActions.setSnackbarMessage({
				message: `An error occurred: ${createError} in addCabinetProduct`,
				type: 'error',
			}),
		);
	}
}

export function* getCabinetProductInventory(action: ReturnType<typeof CabinetActions.getCabinetProductInventory>): any {
	const callback = action.payload.callback || null;
	const defaultView = action.payload.defaultView || 'lot';
	try {
		yield put(UIAction.showLoader(action.payload.showLoader));
		delete action.payload.callback;
		let response: any;
		switch (defaultView) {
			case 'cartridge':
				response = yield call(InventoryManagementService.getInstance().getCartridgeInventory, action.payload.filter);
				break;
			case 'serial':
				response = yield call(InventoryManagementService.getInstance().getSerialInventory, action.payload.filter);
				break;
			default:
				response = yield call(InventoryManagementService.getInstance().getTenantInventory, action.payload.filter);
				break;
		}
		yield put(CabinetActions.setCabinetProductInventory(response.data));
		if (callback) {
			yield call(callback, true);
		}
		yield put(UIAction.showLoader(false));
	} catch (err: any) {
		yield put(UIAction.showLoader(false));
		console.log('Create Cabinet Saga Error', err);
		const getCabinetProductsError = err.message.includes('403')
			? Constants.ALERT.NOT_AUTHORIZED
			: Constants.ALERT.SERVER_ERROR;

		yield put(
			UserActions.setSnackbarMessage({
				message: `An error occurred: ${getCabinetProductsError} in getCabinetProductInventory`,
				type: 'error',
			}),
		);
	}
}

export function* getConsignmentOrders(action: ReturnType<typeof CabinetActions.getConsignmentOrders>): any {
	const callback = action.payload.callback;
	delete action.payload.callback;
	try {
		yield put(CabinetActions.setConsignmentOrders(null));
		const shipments = (yield call(OrdersService.getInstance().getShipments, action.payload.params.shipments)).data
			.result;
		const suspendedTransactions = (yield call(
			InventoryManagementService.getInstance().getSuspendedTransactions,
			action.payload.params.suspendedTransactions,
		)).data.result;
		const suspendedLots = suspendedTransactions.map((suspendedTrans: any) => {
			return suspendedTrans.lot;
		});
		const suspendedShipments = suspendedTransactions.map((suspendedTrans: any) => {
			return suspendedTrans.shipmentId;
		});
		const orders = shipments.map((shipment: any) => {
			if (suspendedLots.indexOf(shipment.lotNumber) >= 0 && suspendedShipments.indexOf(shipment.shipmentId) >= 0) {
				shipment.hasSuspendedLot = true;
			}
			return shipment;
		});
		yield put(CabinetActions.setConsignmentOrders(orders));
		if (callback) {
			callback(true);
		}
	} catch (orderErr: any) {
		if (callback) {
			callback(true);
		}
		const updateError = orderErr.message.includes('403')
			? Constants.ALERT.NOT_AUTHORIZED
			: Constants.ALERT.SERVER_ERROR;
		console.log('Consignment Order Saga Error', orderErr);
		yield put(UIAction.showLoader(false));

		yield put(
			UserActions.setSnackbarMessage({
				message: `An error occurred: ${updateError} in getConsignmentOrders`,
				type: 'error',
			}),
		);
	}
}

export function* updateCabinetProductInventory(action: ReturnType<typeof CabinetActions.getCabinetProductInventory>) {
	try {
		yield put(UIAction.showLoader(true));
		const callback = action.payload.callback || null;
		const isReceipt = action.payload.isReceipt || false;
		delete action.payload.isReceipt;
		delete action.payload.callback;
		const { data } = yield call(InventoryManagementService.getInstance().updateCabinetProductInventory, {
			action: action.payload.action,
			body: action.payload.data,
		});
		if (callback) {
			callback(data.suspended);
			if (data.suspended || isReceipt) {
				yield put(UIAction.showLoader(false));
			}
			return;
		}
		yield put(UIAction.showLoader(false));
	} catch (error: any) {
		yield put(UIAction.showLoader(false));
		const updateError = error.message.includes('403') ? Constants.ALERT.NOT_AUTHORIZED : Constants.ALERT.SERVER_ERROR;

		yield put(
			UserActions.setSnackbarMessage({
				message: `An error occurred: ${updateError} in updateCabinetProductInventory`,
				type: 'error',
			}),
		);
	}
}

export function* reportCabinetInventory(action: ReturnType<typeof CabinetActions.reportCabinetInventory>) {
	try {
		const callback = action.payload.callback || null;
		delete action.payload.callback;
		yield put(UIAction.showLoader(true));
		const { data } = yield call(InventoryManagementService.getInstance().reportCabinetInventory, action.payload);
		if (callback) {
			callback(data.suspended);
		}
		yield put(UIAction.showLoader(false));
	} catch (error: any) {
		console.log('Report Cabinet Inventory Saga Error', error);
		yield put(UIAction.showLoader(false));
		const reportInventoryError = error.message.includes('403')
			? Constants.ALERT.NOT_AUTHORIZED
			: Constants.ALERT.SERVER_ERROR;

		yield put(
			UserActions.setSnackbarMessage({
				message: `An error occurred: ${reportInventoryError} in reportCabinetInventory`,
				type: 'error',
			}),
		);
	}
}

export function* getCabinetLatestScan(action: ReturnType<typeof CabinetActions.setCabinetLatestScan>) {
	try {
		yield put(UIAction.showLoader(true));
		const { data } = yield call(CabinetService.getInstance().getCabinetLatestScan, action.payload);

		console.log('got latest scan:', data.result);
		yield put(CabinetActions.setCabinetLatestScan(data.result));
		yield put(UIAction.showLoader(false));
	} catch (error) {
		console.log('getCabinetLatestScan Saga Error', error);

		yield put(
			UserActions.setSnackbarMessage({
				message: `An error occurred: ${error}`,
				type: 'error',
			}),
		);

		yield put(UIAction.showLoader(false));
	}
}

export function* getProviderById(action: ReturnType<typeof CabinetActions.getProviderById>) {
	try {
		const { data } = yield call(CabinetService.getInstance().getProvider, action.payload.providerId);
		if (data.Errors) {
			throw new Error(JSON.stringify(data.Errors));
		}
		yield put(CabinetActions.setFoundProvider(data));
		action.payload.callback({ loading: false, error: false });
	} catch (error) {
		console.log('getProviderById Saga Error', error);

		yield put(
			UserActions.setSnackbarMessage({
				message: `Failed to find provider with npi number: ${action.payload.providerId}`,
				type: 'error',
			}),
		);

		yield put(UIAction.showLoader(false));
		//action.payload.callback({ loading: false, error: `Failed to find provider with npi number: ${action.payload.providerId}` });
	}
}

export function* addCabinetProvider(action: ReturnType<typeof CabinetActions.addCabinetProvider>) {
	try {
		const { data } = yield call(CabinetService.getInstance().addCabinetProvider, {
			cabinetId: action.payload.cabinetId,
			providerId: action.payload.providerId,
		});
		if (data.Errors) {
			throw new Error(JSON.stringify(data.Errors));
		}
		action.payload.success(data);
	} catch (error) {
		console.log('add cabinet provider Saga Error', error);

		yield put(
			UserActions.setSnackbarMessage({
				message: `An error occurred: ${error}`,
				type: 'error',
			}),
		);

		yield put(UIAction.showLoader(false));
		//action.payload.error();
	}
}

export function* deleteCabinetProviders(action: ReturnType<typeof CabinetActions.deleteCabinetProviders>) {
	try {
		yield put(UIAction.showLoader(true));
		yield call(CabinetService.getInstance().deleteProviders, action.payload.providers);
		action.payload.success();
		yield put(UIAction.showLoader(false));
	} catch (error) {
		console.log('delete cabinet providers saga error', error);
		action.payload.error();
		yield put(UIAction.showLoader(false));

		yield put(
			UserActions.setSnackbarMessage({
				message: `An error occurred: ${error}`,
				type: 'error',
			}),
		);
	}
}

export function* addCabinetLocationTracking(action: ReturnType<typeof CabinetActions.addCabinetLocationTracking>) {
	try {
		yield put(UIAction.showLoader(true));
		const { data } = yield call(CabinetService.getInstance().addCabinetLocationTracking, action.payload.location);
		action.payload.success(data);
		yield put(UIAction.showLoader(false));
	} catch (error: any) {
		console.log('add cabinet location tracking Saga Error', error);
		yield put(UIAction.showLoader(false));
		yield put(
			UserActions.setSnackbarMessage({
				message: error.response.data.error.message,
				type: 'error',
			}),
		);
	}
}

export function* deleteCabinetLocationTracking(
	action: ReturnType<typeof CabinetActions.deleteCabinetLocationTracking>,
) {
	try {
		yield put(UIAction.showLoader(true));
		const { data } = yield call(CabinetService.getInstance().deleteCabinetLocationTracking, action.payload.locations);
		action.payload.success(data);
		yield put(UIAction.showLoader(false));
	} catch (error) {
		console.log('delete cabinet location tracking saga error', error);
		yield put(UIAction.showLoader(false));
		yield put(
			UserActions.setSnackbarMessage({
				message: `An error occurred: ${error}`,
				type: 'error',
			}),
		);
	}
}

export function* getPropertiesByCabinetType(action: ReturnType<typeof CabinetActions.getPropertiesByCabinetType>) {
	try {
		yield put(CabinetActions.setPropertiesByCabinetType(null));
		const { data } = yield call(CabinetService.getInstance().getPropertiesByCabinetType, action.payload.cabinetType);
		yield put(CabinetActions.setPropertiesByCabinetType(data));
		action.payload.success();
	} catch (err) {
		yield put(UIAction.showLoader(false));

		yield put(
			UserActions.setSnackbarMessage({
				message: `An error occurred: ${err}`,
				type: 'error',
			}),
		);
	}
}

export function* saveCabinetProperties(action: ReturnType<typeof CabinetActions.saveCabinetProperties>) {
	try {
		yield call(CabinetService.getInstance().saveCabinetProperties, action.payload.data);
		action.payload.success();
	} catch (err) {
		yield put(UIAction.showLoader(false));
		yield put(
			UserActions.setSnackbarMessage({
				message: `An error occurred: ${err}`,
				type: 'error',
			}),
		);
		action.payload.error();
	}
}

export function* deleteCabinetProperty(action: ReturnType<typeof CabinetActions.deleteCabinetProperty>) {
	try {
		yield call(CabinetService.getInstance().deleteCabinetProperty, action.payload);
		yield put(
			UserActions.setSnackbarMessage({
				message: 'Delete succeeded.',
				type: 'success',
			}),
		);
	} catch (err) {
		yield put(UIAction.showLoader(false));
		yield put(
			UserActions.setSnackbarMessage({
				message: `An error occurred: ${err}`,
				type: 'error',
			}),
		);
	}
}

export function* saveCabinetPropertyMeta(action: ReturnType<typeof CabinetActions.saveCabinetPropertyMeta>) {
	try {
		yield call(CabinetService.getInstance().saveCabinetPropertyMeta, action.payload.data);
		action.payload.success();
	} catch (err) {
		yield put(UIAction.showLoader(false));

		yield put(
			UserActions.setSnackbarMessage({
				message: `An error occurred: ${err}`,
				type: 'error',
			}),
		);
	}
}

export function* getEdgeConfigProperties(action: ReturnType<typeof CabinetActions.getEdgeConfigProperties>): any {
	try {
		console.log('Get Edge Props Called ');
		const { data } = yield call(CabinetService.getInstance().getEdgeConfigProperties);
		console.log('Config props: ', data);
		yield put(CabinetActions.setEdgeConfigProperties(data));
	} catch (err) {
		console.error('Saga Error: getting the Edge Config Properties failed', err);
		yield put(
			UserActions.setSnackbarMessage({
				message: `An error occurred getting the edge config properties ${err}`,
				type: 'error',
			}),
		);
	}
}

export function* updateEdgeConfigProperties(action: ReturnType<typeof CabinetActions.updateEdgeConfigProperties>): any {
	try {
		console.log('Update Edge Props Called');
		yield call(CabinetService.getInstance().updateEdgeConfigProperties, action.payload);

		yield put(
			UserActions.setSnackbarMessage({
				message: `Edge Config Properties update succeeded.`,
				type: 'success',
			}),
		);
	} catch (err) {
		console.log('Update Device Edge Config Properties Saga Error', err);
		yield put(
			UserActions.setSnackbarMessage({
				message: `An error occurred: ${err}`,
				type: 'error',
			}),
		);
	}
}

export function* getFlexConfig(action: ReturnType<typeof CabinetActions.getFlexConfig>): any {
	try {
		yield put(CabinetActions.setFlexConfig(null));
		const { data } = yield call(CabinetService.getInstance().getFlexConfig, action.payload.cabinetId);

		const productListResponse = yield call(
			CabinetService.getInstance().getFlexConfigProductList,
			action.payload.customerId,
		);
		const productList = productListResponse?.data?.results;

		// console.log('$$$$$$ got product list', productList);

		yield put(CabinetActions.setFlexConfig(data));
		yield put(CabinetActions.setFlexProductList(productList));
	} catch (err) {
		console.log('Get Cabinet Flex Config Saga Error', err);
		yield put(
			UserActions.setSnackbarMessage({
				message: `An error occurred: ${err}`,
				type: 'error',
			}),
		);
	}
}

export function* updateFlexConfig(action: ReturnType<typeof CabinetActions.updateFlexConfig>) {
	try {
		const { data } = yield call(CabinetService.getInstance().updateFlexConfig, action.payload);
		action.payload.payload.success();
		yield put(
			UserActions.setSnackbarMessage({
				message: `Cabinet update succeeded.`,
				type: 'success',
			}),
		);

		// //on success, call "get" again to get latest version which was updated by iOt service connecting to device
		// let updatedData: any;
		// updatedData = yield call(CabinetService.getInstance().getFlexConfig, action.payload.cabinetId);

		// yield put(CabinetActions.setFlexConfig(updatedData.data));
	} catch (err) {
		console.log('Update Cabinet Flex Config Saga Error', err);
		yield put(
			UserActions.setSnackbarMessage({
				message: `An error occurred: ${err}`,
				type: 'error',
			}),
		);
	}
}

export function* sendManualReceipt(action: ReturnType<typeof CabinetActions.sendManualReceipt>) {
	try {
		yield put(UIAction.showLoader(true));
		yield call(CabinetService.getInstance().sendManualReceipt, action.payload.requestBody, action.payload.cabinetId);
		action.payload.success();
		yield put(
			UserActions.setSnackbarMessage({
				message: `Manual Receipt succeeded.`,
				type: 'success',
			}),
		);
		yield put(UIAction.showLoader(false));
	} catch (err) {
		yield put(UIAction.showLoader(false));
		console.log('Manual Receipt Saga Error', err);
		yield put(
			UserActions.setSnackbarMessage({
				message: `An error occurred: ${err}`,
				type: 'error',
			}),
		);
	}
}

export function* flexResetDefault(action: ReturnType<typeof CabinetActions.flexResetDefault>): any {
	try {
		yield put(CabinetActions.setFlexConfig(null));
		const { data } = yield call(CabinetService.getInstance().flexResetDefault, action.payload);

		//console.log('$$$$$$ got product list', productList);
		const existingConfig = yield select(state => state.cabinet.cabinetFlexConfig);

		yield put(CabinetActions.setFlexConfig({ ...existingConfig, config: data }));
	} catch (err) {
		console.log('Reset Cabinet Flex Config Saga Error', err);
		yield put(
			UserActions.setSnackbarMessage({
				message: `An error occurred: ${err}`,
				type: 'error',
			}),
		);
	}
}

export function* sendManualCorrection(action: ReturnType<typeof CabinetActions.sendManualCorrection>) {
	try {
		yield put(UIAction.showLoader(true));
		yield call(
			CabinetService.getInstance().sendManualCorrection,
			action.payload.requestBody,
			action.payload.type,
			action.payload.cabinetId,
		);
		action.payload.success();
		yield put(
			UserActions.setSnackbarMessage({
				message: `Manual Correction succeeded.`,
				type: 'success',
			}),
		);
		yield put(UIAction.showLoader(false));
	} catch (err) {
		yield put(UIAction.showLoader(false));
		console.log('Manual Correction Saga Error', err);
		yield put(
			UserActions.setSnackbarMessage({
				message: `An error occurred: ${err}`,
				type: 'error',
			}),
		);
	}
}

export function* sendInventorySync(action: ReturnType<typeof CabinetActions.sendInventorySync>) {
	try {
		yield put(UIAction.showLoader(true));
		const { data } = yield call(
			CabinetService.getInstance().sendInventorySync,
			action.payload.requestBody,
			action.payload.syncAction,
			action.payload.cabinetId,
		);
		if (data.resultCode !== 0) {
			throw new Error('Inventory Sync failed.');
		}
		action.payload.success();
		yield put(
			UserActions.setSnackbarMessage({
				message: `Inventory Sync succeeded.`,
				type: 'success',
			}),
		);
		yield put(UIAction.showLoader(false));
	} catch (err: any) {
		yield put(UIAction.showLoader(false));
		console.log('Inventory Sync Saga Error', err);
		yield put(
			UserActions.setSnackbarMessage({
				message: `An error occurred: ${err.message}`,
				type: 'error',
			}),
		);
	}
}

export function* getDeviceTags(action: ReturnType<typeof CabinetActions.sendManualCorrection>) {
	try {
		yield put(CabinetActions.storeDeviceTags(null));
		const { data } = yield call(CabinetService.getInstance().getDeviceTags, action.payload);
		yield put(CabinetActions.storeDeviceTags(data));
	} catch (err) {
		yield put(UIAction.showLoader(false));
		console.log('Device Tags Saga Error', err);
		yield put(
			UserActions.setSnackbarMessage({
				message: `An error occurred while getting device tags: ${err}`,
				type: 'error',
			}),
		);
	}
}

export function* setDeviceTags(action: ReturnType<typeof CabinetActions.sendManualCorrection>) {
	try {
		yield put(UIAction.showLoader(true));
		yield call(CabinetService.getInstance().setDeviceTags, action.payload.requestBody, action.payload.cabinetId);
		yield call(action.payload.success);
		yield put(
			UserActions.setSnackbarMessage({
				message: `Save Device Tags succeeded.`,
				type: 'success',
			}),
		);
		yield put(UIAction.showLoader(false));
	} catch (err) {
		yield put(UIAction.showLoader(false));
		console.log('Set Device Tags Saga Error', err);
		yield put(
			UserActions.setSnackbarMessage({
				message: `An error occurred: ${err}`,
				type: 'error',
			}),
		);
	}
}

export function* getCabinetReceipts(action: ReturnType<typeof CabinetActions.getCabinetReceipts>) {
	try {
		yield put(CabinetActions.setCabinetReceipts(null));
		const { data } = yield call(CabinetService.getInstance().getCabinetReceipts, action.payload);
		if (data.resultCode !== 0) throw new Error(data.resultMessage);
		yield put(CabinetActions.setCabinetReceipts(data.resultValue));
	} catch (err) {
		yield put(UIAction.showLoader(false));
		console.log('Cabinet Receipts Saga Error', err);
		yield put(
			UserActions.setSnackbarMessage({
				message: `An error occurred while getting cabinet receipts: ${err}`,
				type: 'error',
			}),
		);
	}
}

export function* rejectCabinetReceipt(action: ReturnType<typeof CabinetActions.rejectCabinetReceipt>) {
	try {
		yield put(UIAction.showLoader(true));
		yield call(CabinetService.getInstance().rejectCabinetReceipt, action.payload.requestBody, action.payload.cabinetId);
		yield put(
			UserActions.setSnackbarMessage({
				message: `Receipt for shipment id ${action.payload.requestBody.shipmentId} has been rejected.`,
				type: 'success',
			}),
		);
		const { data } = yield call(CabinetService.getInstance().getCabinetReceipts, action.payload.cabinetId);
		if (data.resultCode !== 0) throw new Error(data.resultMessage);
		yield put(CabinetActions.setCabinetReceipts(data.resultValue));
		yield put(UIAction.showLoader(false));
	} catch (err) {
		yield put(UIAction.showLoader(false));
		console.log('Reject Cabinet Receipt Saga Error', err);
		yield put(
			UserActions.setSnackbarMessage({
				message: `An error occurred: ${err}`,
				type: 'error',
			}),
		);
	}
}

export function* getCabinetConnectivity(action: ReturnType<typeof CabinetActions.getCabinetConnectivityGraph>) {
	try {
		let params = Object.keys(action.payload).map((param: string) => {
			return {
				key: param,
				value: action.payload[param],
			};
		});
		const { data } = yield call(CabinetService.getInstance().getCabinetConnectivity, params);
		yield put(CabinetActions.setCabinetConnectivityGraph(data));
	} catch (err) {
		console.log('saga error' + err);
	}
}

export function* getCabinetLteWifi(action: ReturnType<typeof CabinetActions.getCabinetLteWifiGraph>) {
	try {
		// let params = Object.keys(action.payload).map((param: string) => {
		// 	return {
		// 		key: param,
		// 		value: action.payload[param]
		// 	}
		// })
		// params.push({key: 'order', value: ['eventTimestamp asc']})
		const params = [
			{
				key: 'filter',
				value: JSON.stringify({
					order: ['eventTimestamp asc'],
					where: {
						and: [
							{ deviceId: action.payload.deviceId },
							{ eventTimestamp: { gt: action.payload.startTime } },
							{ eventTimestamp: { lt: action.payload.endTime } },
						],
					},
				}),
			},
		];
		const { data } = yield call(CabinetService.getInstance().getCabinetLteWifi, params);
		yield put(CabinetActions.setCabinetLteWifiGraph(data));
	} catch (err) {
		console.log('saga error' + err);
	}
}

export function* getSecureDeviceCompartments(action: ReturnType<typeof CabinetActions.getSecureDeviceCompartments>) {
	try {
		const { data } = yield call(CabinetService.getInstance().getSecureDeviceCompartments, action.payload.deviceId);
		action.payload.success(data)
	} catch (err) {
		console.log('Get secure device compartments Saga Error', err);
		action.payload.error()
	}
}
