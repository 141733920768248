import Api from '../lib/api';
import { CABINET, TENANTS } from '../constants/api.url';
import { HttpResponse, ResultSet } from '../models/common/result.set';
import { CabinetProductResponse, CabinetFilter, CabinetInventoryRowResponse } from '../models/cabinets/cabinet';
import TimestampNormalizer from '../lib/timestamp-normalizer';
import { UpdateDeviceEdgePropertiesPayloadDTO } from '../models/cabinets/device-edge-properties-payload.dto';
import { UpdateEdgeConfigParams } from '../models/cabinets/update-edge-config-params.type';

export class CabinetService {
	private static instance: CabinetService;
	private constructor() {}

	public static getInstance(): CabinetService {
		if (!CabinetService.instance) {
			CabinetService.instance = new CabinetService();
		}
		return CabinetService.instance;
	}

	public getCabinets = async (filter?: any): Promise<HttpResponse<ResultSet<any>>> =>
		await Api.post(CABINET.LIST, filter);
	public getContextCabinets = async (filter?: any): Promise<HttpResponse<ResultSet<any>>> =>
		await Api.get(CABINET.URL + `/customerCabinetsByContext?customerId=${filter.customerId}&context=${filter.context}`);
	public getCabinetsWithProperties = async (filter?: any): Promise<HttpResponse<ResultSet<CabinetProductResponse>>> =>
		await Api.post(CABINET.PROPERTIES, filter);
	public getCabinetInventory = async (
		filter?: CabinetFilter,
	): Promise<HttpResponse<ResultSet<CabinetInventoryRowResponse>>> => await Api.post(CABINET.FILTER, filter); //remove? not called and endpoint is being replaced by /cabinets/list

	public fetchCabinetStates = async (filter?: any): Promise<HttpResponse<ResultSet<any>>> =>
		await Api.post(CABINET.STATE, filter);

	public getCabinetDetails = async (cabinetId: string, customerId: string): Promise<HttpResponse<any>> =>
		await Api.get(`${CABINET.DETAILS}/${cabinetId}?customerId=${customerId}`);

	public getPropertyList = async (): Promise<HttpResponse<any>> => await Api.get(`${CABINET.URL}/propertyList`);

	public getCabinetTemperature = async (filter?: any): Promise<any> => {
		const response = await Api.get(`${CABINET.TEMPERATURE}?filter=${filter}`);
		const timestampNormalizer = new TimestampNormalizer(response.data);
		const tempData = timestampNormalizer.convertToLocal('tempTimestamp');
		return tempData;
	};

	public getCabinetProducts = async (cabinetId: string): Promise<HttpResponse<any>> =>
		await Api.get(`${CABINET.URL}/${cabinetId}/products`);

	public updatePoType = async (payload: any): Promise<HttpResponse<any>> =>
		await Api.put(`${CABINET.URL}/${payload.cabinetId}/products/${payload.productItemId}`, { poType: payload.poType });

	public updateCabinet = async (cabinetId: string, customerId: string, payload: any): Promise<any> =>
		await Api.patch(`${CABINET.URL}/${cabinetId}?customerId=${customerId}`, payload);

	public getCabinetUsers = async (cabinetId: string, cabinetUserId: any = null): Promise<HttpResponse<any>> => {
		let url = `${CABINET.CABINET_USERS}/${cabinetId}`;
		if (cabinetUserId) {
			url += `?userId=${cabinetUserId}`;
		}
		return await Api.get(url);
	};

	public addCabinetUser = async (cabinetId: string, user: any): Promise<HttpResponse<any>> =>
		await Api.post(`${CABINET.CABINET_USERS}/${cabinetId}`, user);

	public updateCabinetUser = async (user: any): Promise<HttpResponse<any>> =>
		await Api.patch(`${CABINET.CABINET_USERS}`, user);

	public createCabinet = async (cabinet: any): Promise<HttpResponse<any>> => await Api.post(`${CABINET.NEW}`, cabinet);

	public addProduct = async (product: any): Promise<HttpResponse<any>> =>
		await Api.post(`${CABINET.URL}/products`, product);

	public getCabinetTemperatureCsv = async (
		customerId: string,
		deviceId: number,
		timezone: string,
		startTime: string,
		endTime: string,
	): Promise<HttpResponse<any>> =>
		await Api.get(
			`${CABINET.TEMPERATURE}/csv?customerId=${customerId}&deviceId=${deviceId}&timezone=${timezone}&startTime=${startTime}&endTime=${endTime}`,
		);

	public getCabinetLatestScan = async (cabinetId: string): Promise<HttpResponse<any>> => {
		return await Api.get(`${CABINET.VIPC}/getLastScan/${cabinetId}`);
	};

	public getProvider = async (providerId: string): Promise<HttpResponse<any>> =>
		await Api.get(`${CABINET.URL}/provider/lookup/${providerId}`);

	public addCabinetProvider = async (payload: any): Promise<HttpResponse<any>> =>
		await Api.post(`${CABINET.URL}/provider`, payload);

	public deleteProviders = async (payload: any): Promise<HttpResponse<any>> =>
		await Api.delete(`${CABINET.URL}/provider`, payload);

	public addCabinetLocationTracking = async (payload: any): Promise<HttpResponse<any>> =>
		await Api.post(`${CABINET.URL}/location-tracking`, payload);

	public deleteCabinetLocationTracking = async (payload: any): Promise<HttpResponse<any>> =>
		await Api.delete(`${CABINET.URL}/location-tracking`, payload);

	public getPropertiesByCabinetType = async (cabinetType: string): Promise<HttpResponse<any>> =>
		await Api.get(`${CABINET.URL}/propertySettings/${cabinetType}`);

	public saveCabinetProperties = async (payload: any): Promise<HttpResponse<any>> =>
		await Api.patch(`${CABINET.URL}/${payload.cabinetId}`, payload);

	public deleteCabinetProperty = async (payload: any): Promise<HttpResponse<any>> =>
		await Api.delete(`${CABINET.URL}/setPropertyMeta`, {
			cabinetType: payload.cabinetType,
			propertyKey: payload.propertyKey,
		});

	public saveCabinetPropertyMeta = async (payload: any): Promise<HttpResponse<any>> =>
		await Api.post(`${CABINET.URL}/setPropertyMeta`, payload);

	public getEdgeConfigProperties = async (): Promise<HttpResponse<any>> =>
		await Api.get(`${CABINET.EDGE_CONFIG_PROPERTIES}/edge-config-properties`);

	public updateEdgeConfigProperties = async (params: UpdateEdgeConfigParams): Promise<HttpResponse<any>> =>
		await Api.post(
			`${CABINET.EDGE_CONFIG_PROPERTIES}/${params.techType}/update-edge-config-properties/`,
			params.payload,
		);

	public updateParLevels = async (payload: any): Promise<HttpResponse<any>> => {
		return await Api.patch(`${TENANTS.PRODUCTS}/${payload.customerProductId}`, {
			maxSet: payload.maxSet ? parseInt(payload.maxSet) : null,
			minSet: payload.minSet ? parseInt(payload.minSet) : null,
			crl: payload.crl ? parseInt(payload.crl) : null,
			maxFill: parseInt(payload.maxFill),
			poType: payload.poType,
			salesType: payload.salesType,
			productId: payload.productId,
			cabinetType: payload.cabinetType,
			active: payload.active,
			packToLum: payload.packToLum,
			defaultSapProduct: payload.defaultSapProduct,
			notes: payload.notes || null,
		});
	};

	public getFlexConfig = async (cabinetId: any): Promise<HttpResponse<any>> => {
		return await Api.get(`${CABINET.FLEX_CONFIG}/${cabinetId}`);
	};

	public getFlexConfigProductList = async (customerId: any): Promise<HttpResponse<any>> => {
		const filterString = JSON.stringify({ where: { customerId: customerId } });
		return await Api.get(`${TENANTS.PRODUCTS}`, [{ key: 'filter', value: filterString }]);
	};

	public updateFlexConfig = async (payload: any): Promise<HttpResponse<any>> => {
		const cabinetId = payload.cabinetId;

		return await Api.post(`${CABINET.FLEX_CONFIG}/${cabinetId}`, payload.payload.requestBody);
	};

	public sendManualReceipt = async (payload: any, cabinetId: string): Promise<HttpResponse<any>> => {
		return await Api.post(`${CABINET.MANUAL_RECEIPT}/${cabinetId}`, payload);
	};

	public getCabinetReceipts = async (cabinetId: string): Promise<HttpResponse<any>> => {
		return await Api.get(`${CABINET.RECEIPTS}/${cabinetId}`);
	};

	public flexResetDefault = async (cabinetId: string): Promise<HttpResponse<any>> => {
		return await Api.get(`${CABINET.FLEX_CONFIG}/set_default_config/${cabinetId}`);
	};

	public sendManualCorrection = async (payload: any, type: string, cabinetId: string): Promise<HttpResponse<any>> => {
		return await Api.post(`${CABINET.COMMAND}/correction/${type}/${cabinetId}`, payload);
	};

	public sendInventorySync = async (payload: any, action: string, cabinetId: string): Promise<HttpResponse<any>> => {
		return await Api.post(`${CABINET.COMMAND}/inventory_sync/${action}/${cabinetId}`, payload);
	};

	public getDeviceTags = async (cabinetId: string): Promise<HttpResponse<any>> => {
		return await Api.get(`${CABINET.DEVICE_TAGS}/${cabinetId}`);
	};

	public setDeviceTags = async (payload: any, cabinetId: string): Promise<HttpResponse<any>> => {
		return await Api.post(`${CABINET.DEVICE_TAGS}/${cabinetId}`, payload);
	};

	public rejectCabinetReceipt = async (payload: any, cabinetId: string): Promise<HttpResponse<any>> => {
		return await Api.post(`${CABINET.COMMAND}/receipt/reject/${cabinetId}`, payload);
	};

	public getCabinetConnectivity = async (params: any): Promise<HttpResponse<any>> => {
		return await Api.get(CABINET.CONNECTIVITY, params);
	};

	public getCabinetLteWifi = async (params: any): Promise<HttpResponse<any>> => {
		return await Api.get(CABINET.LTEWIFI, params);
	};

	public getTelemetryMetrics = async (params: any): Promise<HttpResponse<any>> => {
		return await Api.get(CABINET.TELEMETRY_METRICS, params);
	};

	public getSecureDeviceCompartments = async (deviceId: any): Promise<HttpResponse<any>> => {
		return await Api.get(CABINET.TEMPERATURE + '/' + deviceId + '/compartments');
	};
}
