import * as React from 'react';
import { connect } from 'react-redux';
import GlobalFiltersComponentContainer from '../../components/globalFiltersComponent';
import { Grid } from '@material-ui/core';
import { AuditLogStyles } from './audit-logs-styles';
import AuditLogsTable from './audit-logs-table';
import { AuditActions } from '../../redux/actions';

const AuditLogsContainer: React.FC<any> = (props: any) => {
	const classes = AuditLogStyles();

	const searchAuditLogs = () => {
		fetchList({ offset: 0, limit: props.globalRowsPerPage, page: 0 });
	};

	const fetchList = (page: any) => {
		let paylaod: any = {
			offset: page.offset,
			limit: page.limit,
			skip: 0,
			order: 'createdAt DESC',
			where: {},
			fields: {
				id: true,
				service: true,
				customerId: true,
				resourceId: true,
				resourceType: true,
				eventType: true,
				initiatorType: true,
				createdBy: true,
				createdAt: true,
				description: true,
				data: true,
			},
		};
		const filterValues = props.selectedFilterValues || {};
		paylaod.where['customerId'] =
			props.selectedCustomer && props.selectedCustomer.customerId ? props.selectedCustomer.customerId : undefined;
		if (filterValues['RESOURCE_TYPE']) paylaod.where['resourceType'] = filterValues['RESOURCE_TYPE'];
		if (filterValues['INITIATOR_TYPE']) paylaod.where['initiatorType'] = filterValues['INITIATOR_TYPE'];
		paylaod.where['createdAt'] = props.selectedDateRange
			? { between: [props.selectedDateRange.startDate, props.selectedDateRange.endDate] }
			: undefined;
		props.getAuditLogList(paylaod);
	};

	return (
		<div>
			<div className={classes.searchWrap}>
				<Grid container>
					<Grid item xs={12}>
						<GlobalFiltersComponentContainer pageName="AUDITLOG" executeSearch={searchAuditLogs} />
					</Grid>
					<Grid item xs={12}>
						<AuditLogsTable fetchList={fetchList} />
					</Grid>
				</Grid>
			</div>
		</div>
	);
};

const mapStateToProps = (state: any) => ({
	selectedDateRange: state.ui.selectedDateRange,
	selectedCustomer: state.ui.selectedCustomer,
	selectedFilterValues: state.ui.selectedFilterValues,
	globalRowsPerPage: state.ui.globalRowsPerPage ? state.ui.globalRowsPerPage : 10,
});

const mapDispatchToProps = (dispatch: any) => ({
	getAuditLogList: (payload: any) => dispatch(AuditActions.getAuditLogList(payload)),
});

export default connect(mapStateToProps, mapDispatchToProps)(AuditLogsContainer);
