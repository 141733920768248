import React from 'react';
import { connect, useDispatch } from 'react-redux';
import dataList from '../../constants/dataList';
import { GlobalFiltersComponent } from '@fffenterprises/global-filters/lib/global-filters-component';
import {
	UIAction,
	TenantsActions,
	ProductActions,
	InventoryManagementActions,
	ReportActions,
	CabinetActions,
	OrderActions,
	UserManagementActions,
	InvoicesActions,
	AlertActions,
	AuditActions,
} from '../../redux/actions';
import { Constants } from '../../constants/Constants';
import { Cabinet, CabinetProperties } from '../../components/autoComplete';

const AdvanceFilters = () => {
	return (
		<></>
	);

}

//Component takes in both state props, and "regular" props as "ownProps", passed in from the page where this Container is embedded
const mapStateToProps = (state: any, ownProps: any) => ({
	selectedResource: state.ui.selectedResource,
	selectedInitiatedBy: state.ui.selectedInitiatedBy,
	selectedCustomer: state.ui.selectedCustomer,
	selectedCabinetType: state.ui.selectedCabinetType,
	selectedExpiryDate: state.ui.selectedExpiryDate,
	selectedDateRange: state.ui.selectedDateRange,
	selectedFilterValues: state.ui.selectedFilterValues,
	selectedHistoryDate: state.ui.selectedHistoryDate,
	selectedTransmittalDate: state.ui.selectedTransmittalDate,
	autoCompleteProducts: state.product.autoCompleteList || [],
	allProductsList: state.product.allProductsList || [],
	productGroups: state.product.productGroups || [],
	incidentRules: state.alerts.incidentRules || [],
	incidentAlertTypeList: state.alerts.incidents ? state.alerts.incidents.alertTypeList : [],
	statesList: state.cabinet.stateList ? state.cabinet.stateList.result : [],
	properties:
		state.user?.authUser?.record?.properties && state.user.authUser.record.properties instanceof Array
			? state.user.authUser.record.properties
			: [],
	customers: state.tenants?.tenantsList?.result || [],
	selectedSaveSearchPageName: state.ui.selectedSaveSearchPageName,
	selectedSaveSearchTitle: state.ui.selectedSaveSearchTitle,
	selectedSavedFilter: state.ui.selectedSavedFilter,
	disableSerialNumberFilter: state.ui.disableSerialNumberFilter ? state.ui.disableSerialNumberFilter : false,
	disableOwnershipFilter: state.ui.disableOwnershipFilter ? state.ui.disableOwnershipFilter : false,
	authUser: state.user.authUser,
	dataList: dataList,
	Constants: Constants,
	Cabinet: Cabinet,
	CabinetProperties: CabinetProperties,
	AdvanceFilters: AdvanceFilters,
	classOfTradeOptions: state.tenants.classOfTradeOptions ? state.tenants.classOfTradeOptions : [],
	facilityTypeOptions: state.tenants.facilityTypeOptions ? state.tenants.facilityTypeOptions : [],
});

const mapDispatchToProps = (dispatch: any) => ({
	resetAllFilters: () => dispatch(UIAction.resetAllFilters()),
	searchTenants: (filter: any) => dispatch(TenantsActions.getTenantList(filter)),
	setSelectedCustomer: (customer: any) => dispatch(UIAction.setSelectedCustomer(customer)),
	setSelectedCabinetType: (cabinetType: any) => dispatch(UIAction.setSelectedCabinetType(cabinetType)),
	setSelectedDateRange: (dates: any) => dispatch(UIAction.setSelectedDateRange(dates)),
	setSelectedHistoryDate: (date: any) => dispatch(UIAction.setSelectedHistoryDate(date)),
	setSelectedTransmittalDate: (date: any) => dispatch(UIAction.setSelectedTransmittalDate(date)),
	setMoreFilterValue: (payload: any) => dispatch(UIAction.setMoreFilterValue(payload)),
	setSelectedExpiryDate: (expiryDate: any) => dispatch(UIAction.setSelectedExpiryDate(expiryDate)),
	getAutoCompleteProducts: (filter: any) => dispatch(ProductActions.getProductAutoCompleteList(filter)),
	tenantInventoryClearAction: () => dispatch(InventoryManagementActions.tenantInventoryClearAction()),
	clearGlobalReportsData: () => dispatch(ReportActions.clearGlobalData()),
	clearCabinetsData: () => dispatch(CabinetActions.clearCabinetList()),
	clearOrdersData: () => dispatch(OrderActions.resetOrders()),
	clearUserData: () => dispatch(UserManagementActions.resetUsersData()),
	clearTenantsList: () => dispatch(TenantsActions.clearTenantsList()),
	clearInvoicesList: () => dispatch(InvoicesActions.clearInvoicesList()),
	clearAlerts: () => dispatch(AlertActions.alertsRequestCompletedAction(null)),
	clearIncidents: () => dispatch(AlertActions.setIncidents(null)),
	clearProducts: () => dispatch(ProductActions.setProductList(null)),
	clearAuditLogs: () => dispatch(AuditActions.setAuditLogList(null)),
	clearDispensedProducts: () => dispatch(ReportActions.setDispensedProducts(null)),
});

const GlobalFiltersComponentContainer = connect(mapStateToProps, mapDispatchToProps)(GlobalFiltersComponent);

export default GlobalFiltersComponentContainer;
